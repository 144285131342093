import React from "react";
import { Oval } from "react-loader-spinner";

const Loading = ({ loading, width = 60, height = 60 }) => {
  return (
    <Oval
      visible={loading}
      height={height}
      width={width}
      color="#2c8ec6"
      secondaryColor="#9e9e9e"
      ariaLabel="oval-loading"
      strokeWidth={4}
    />
  );
};

export default Loading;
