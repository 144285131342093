import { getAllFuelTypes } from "../api/data";

export const htmlLegendPlugin = {
  id: "htmlLegend",
  afterUpdate(chart, args, options) {
    const legendContainer = document.getElementById(options.containerID);
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    getAllFuelTypes().then((res) => {
      const allFuelFamily = res.data;

      // We'll store: groupedFuels[familyName][originId] = array of { name, color, datasetIndex, hidden }
      const groupedFuels = {};
      const visibleFuelFamilies = new Set();
      const visibleOrigins = new Set();

      // 1) Group by fuel family & origin, but only if it appears in the chart
      allFuelFamily.forEach((fuel) => {
        const familyName = fuel.family.fuelFamilyName;
        const originId = fuel.origin.id;

        const matchingLegendItem = items.find((i) => i.text === fuel.fuelTypeName);
        if (matchingLegendItem) {
          // Initialize structure
          groupedFuels[familyName] = groupedFuels[familyName] || {};
          groupedFuels[familyName][originId] = groupedFuels[familyName][originId] || [];

          // Add fuel info
          groupedFuels[familyName][originId].push({
            name: fuel.fuelTypeName,
            color: matchingLegendItem.fillStyle,
            datasetIndex: matchingLegendItem.datasetIndex,
            hidden: matchingLegendItem.hidden,
          });

          visibleFuelFamilies.add(familyName);
          visibleOrigins.add(originId);
        }
      });

      // 2) Convert Sets to sorted Arrays (for consistent row/column order)
      const familyArray = Array.from(visibleFuelFamilies).sort();
      const originArray = Array.from(visibleOrigins).sort();

      // 3) Clear previous legend content
      while (legendContainer.firstChild) {
        legendContainer.firstChild.remove();
      }

      // 4) Create a single grid container
      const legendGrid = document.createElement("div");
      legendGrid.className = "legend-grid";

      // - # of columns = number of distinct origins
      // - # of rows   = number of distinct families
      legendGrid.style.display = "grid";
      legendGrid.style.gridTemplateColumns = `repeat(${originArray.length}, 1fr)`;
      legendGrid.style.gridTemplateRows = `repeat(${familyArray.length}, auto)`;
      legendGrid.style.gap = "0"; // spacing between cells

      // 5) Fill the grid row-by-row, col-by-col
      familyArray.forEach((familyName, rowIndex) => {
        originArray.forEach((originId, colIndex) => {
          const legendCell = document.createElement("div");
          legendCell.className = "legend-cell";

          // If no fuels in this intersection, do nothing
          const fuelsHere = groupedFuels[familyName]?.[originId] || [];
          fuelsHere.forEach((fuel) => {
            const itemDiv = document.createElement("div");
            itemDiv.className = "legend-item d-flex align-items-center gap-2 cursor-pointer";

            const colorBox = document.createElement("div");
            colorBox.className = "color-box";
            colorBox.style.backgroundColor = fuel.color;

            const fuelName = document.createElement("span");
            fuelName.textContent = fuel.name;
            fuelName.style.textDecoration = fuel.hidden ? "line-through" : "";

            itemDiv.appendChild(colorBox);
            itemDiv.appendChild(fuelName);
            legendCell.appendChild(itemDiv);

            // Toggle dataset visibility on click
            itemDiv.addEventListener("click", () => {
              chart.setDatasetVisibility(
                fuel.datasetIndex,
                !chart.isDatasetVisible(fuel.datasetIndex)
              );
              chart.update();
            });
          });

          // Place this cell into the grid
          // (Browser automatically places them in row-major order if we simply append)
          legendGrid.appendChild(legendCell);
        });
      });

      // 6) Finally append the grid to the container
      legendContainer.appendChild(legendGrid);
    });
  },
};
