import React, { useEffect, useRef, useState } from "react";

import { formatNumber } from "../../utils/formatter";
import Loading from "../Loading";
import DownArrowIcon from "../../assets/arrowDownIcon.svg";
import UpArrowIcon from "../../assets/arrowUpIcon.svg";
import InfoModal from "../InfoModal";
import { useSelector } from "react-redux";
import CustomTooltip from "../CustomTooltip";

const RecommendationTab = ({ activeTab, showBtn = true }) => {
  const { fromYear } = useSelector((state) => state.selectedYearItem);
  const data = useSelector((state) => state.pdfData);
  const legislativeScenarioName = data.legislativeScenarioName;
  const pricingScenarioName = data.priceScenarioName;

  // define shorthand for the different JSON sections
  const ciiData = data?.myCiiResults;
  const eufmData = data?.myFMResults;
  const speedData = data?.mySpeedResults;
  const fuelMix = data?.myResultsFuelMix;

  // setter/getter
  const [vesselName, setVesselName] = useState([]);
  const [ciiValue, setCiiValue] = useState([]);
  const [ciiBackground, setCiiBackground] = useState([]);

  const [eufmDataset, setEufmDataset] = useState([]);
  const [speedDataset, setSpeedDataset] = useState([]);
  const [fuelMixDataset, setFuelMixDataset] = useState([]);

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });

  const [show, setShow] = useState(false);

  const newData = vesselName.map((item, index) => ({
    vessel: item,
    CII: ciiValue[index],
    ciiBackground: ciiBackground[index],
    id: index + 1,
    speed: speedDataset[index],
    fuel: fuelMixDataset[index],
    eufm: eufmDataset[index],
  }));

  useEffect(() => {
    if (data && ciiData && eufmData && speedData && fuelMix) {
      const resultCii = ciiData?.myResultsCII;

      const resultEuFm = eufmData?.myResultsFM;
      const resultSpeed = speedData?.myResultsSpeed;
      const resultFuelMix = fuelMix?.myResultsFuelMix;
      let euFmDatasets = [];
      let speedDatasets = [];
      let fuelMixDatasets = [];

      let vesselName = Object.keys(resultCii);

      // 1. Collect data for the FEUM mini-kpis
      // First, compute the maximum absolute value of fmcredit_mtCO2eq
      const maxAbs = resultEuFm
        ? Math.max(
          ...Object.values(resultEuFm).map(entry =>
            Math.abs(entry.poolTrade_gCO2eq_basis365 ?? 0)
          )
        )
        : 0;
      vesselName.forEach((name) => {
        const vesselJSON = [resultEuFm[name]];
        // Then, map over eufm and return your desired object
        const data = vesselJSON.map((e) => {
          // If maxAbs is 0, use 0 to avoid division by zero
          const miniKPIlength = maxAbs === 0
            ? 0
            : +(e.poolTrade_gCO2eq_basis365 / maxAbs).toFixed(2);
          return {
            label: name,
            reportedFMintensity: +e.reportedIntensity_gCo2eq_MJ?.toFixed(2),
            attainedFMintensity_UnaidedByF:
              +e.unaidedIntensity_gCo2eq_MJ?.toFixed(2),
            fmcredit_gCO2eq: +e.poolTrade_gCO2eq_basis365?.toFixed(2),
            fmcredit_mtCO2eq: +((e.poolTrade_gCO2eq_basis365 ?? 0) / 1e6).toFixed(2),
            miniKPIlength
          };
        });

        euFmDatasets.push(data);
      });
      setEufmDataset(euFmDatasets);

      // 2. Collect data for the speed mini-kpis
      vesselName.forEach((name) => {
        const speed = [resultSpeed[name]];
        const data = speed.map((e, index) => {
          return {
            label: e.vesselName,
            celerity: e.celerity,
            celerityColor: e.celerityColor,
            maxSpeed: e.maxSpeed,
            minSpeed: e.minSpeed,
            optimalSpeed: e.optimalSpeed,
            targetSpeed: e.targetSpeed,
          };
        });
        speedDatasets.push(data);
      });
      setSpeedDataset(speedDatasets);

      // 3. Collect data for the cii mini-kpis
      let cii = Object.values(resultCii).map((item) => item.ciidesignation);
      let ciiBackground = Object.values(resultCii).map((item) => item.colorHex);
      setVesselName(vesselName);
      setCiiValue(cii);
      setCiiBackground(ciiBackground); // background colors for cii

      // 4. Collect data for the fuel mix mini-kpis
      vesselName.forEach((name) => {
        const fuelMix = resultFuelMix[name];
        const tempData = Object.values(fuelMix).map((item) => item);

        // Sort tempData by originString
        tempData.sort((a, b) => a.originId - b.originId);

        const uniqueName = [...new Set(tempData.map((p) => p.originString))];

        const data = tempData.map((p, index) => {
          return {
            label: uniqueName[index],
            data: +p.ptg.toFixed(2),
            backgroundColor: p.colorHex,
            originString: p.originString,
            vesselname: name,
          };
        });

        fuelMixDatasets.push(data);
      });
      setFuelMixDataset(fuelMixDatasets);

    }
  }, [data]);

  const requestSort = (key) => {
    let direction = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = [...newData].sort((a, b) => {
    if (sortConfig.key === "vessel") {
      if (sortConfig.direction === "asc") {
        return a[sortConfig.key] < b[sortConfig.key] ? -1 : 1;
      } else {
        return a[sortConfig.key] > b[sortConfig.key] ? -1 : 1;
      }
    } else if (sortConfig.key === "CII") {
      if (sortConfig.direction === "asc") {
        return a[sortConfig.key] < b[sortConfig.key] ? -1 : 1;
      } else {
        return a[sortConfig.key] > b[sortConfig.key] ? -1 : 1;
      }
    } else if (sortConfig.key === "speed") {
      const optimalSpeedA = a.speed[0]?.optimalSpeed || 0;
      const optimalSpeedB = b.speed[0]?.optimalSpeed || 0;
      if (sortConfig.direction === "asc") {
        return optimalSpeedA - optimalSpeedB;
      } else {
        return optimalSpeedB - optimalSpeedA;
      }
    } else if (sortConfig.key === "fuel") {
      const fossilA =
        a.fuel.find((item) => item.originString === "fossil")?.data || 0;
      const fossilB =
        b.fuel.find((item) => item.originString === "fossil")?.data || 0;

      if (sortConfig.direction === "asc") {
        return fossilA - fossilB;
      } else {
        return fossilB - fossilA;
      }
    } else if (sortConfig.key === "eufm") {
      const attainedFMintensityA = a.eufm[0]?.fmcredit_mtCO2eq || 0;
      const attainedFMintensityB = b.eufm[0]?.fmcredit_mtCO2eq || 0;
      if (sortConfig.direction === "asc") {
        return attainedFMintensityA - attainedFMintensityB;
      } else {
        return attainedFMintensityB - attainedFMintensityA;
      }
    } else {
      return 0;
    }
  });

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (activeTab === 6) {
      setAnimate(true);
      setTimeout(() => {
        setAnimate(false);
      }, 1000);
    }
  }, [activeTab]);

  const RecommendationContainerRef = useRef(null);
  const recommendationInfoRef = useRef(null);

  useEffect(() => {
    if (show) {
      RecommendationContainerRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [show]);

  const handleClose = () => {
    setShow(false);
    recommendationInfoRef?.current?.scrollIntoView({ behavior: "auto" });
  };

  return (
    <div ref={RecommendationContainerRef}>
      <div className="tab-content-title d-flex justify-content-between flex-wrap align-items-start">
        {/* Left side: Title + Subtitles stacked */}
        <div>
          <h2>
            Recommendations <span className="fs-4"> {fromYear.item}</span>
          </h2>
          {/* Subtitles, smaller font */}
          <div className="fs-6">
            <span className="d-block">
              <span className="fw-normal">Legislative Scenario:</span>{" "}
              <span className="fw-bold">{legislativeScenarioName}</span>
            </span>
            <span className="d-block">
              <span className="fw-normal">Pricing Scenario:</span>{" "}
              <span className="fw-bold">{pricingScenarioName}</span>
            </span>
          </div>
        </div>

        {/* Right side: Toggle + Button */}
        {showBtn && (
          <button className="btn btn-primary" onClick={() => setShow(!show)}>
            Notes and Sources
          </button>
        )}
      </div>
      <div className="fleet-chart-container h-auto">
        {Object.keys(data).length === 0 && (
          <div className="d-flex align-items-center justify-content-center h-100">
            <Loading loading={Object.keys(data).length === 0} />
          </div>
        )}
        {Object.keys(data).length > 0 && (
          <div className="table-responsive">
            <table className="table-recommendation">
              <thead>
                <tr className="cursor-pointer">
                  <th onClick={() => requestSort("vessel")}>
                    <span className="mr-sm">Vessel{" "}</span>
                    <img
                      src={
                        sortConfig.direction === "asc" &&
                          sortConfig.key === "vessel"
                          ? UpArrowIcon
                          : DownArrowIcon
                      }
                      alt=""
                    />
                  </th>
                  <th onClick={() => requestSort("CII")}>
                    <span className="mr-sm">CII{" "}</span>
                    <img
                      src={
                        sortConfig.direction === "asc" &&
                          sortConfig.key === "CII"
                          ? UpArrowIcon
                          : DownArrowIcon
                      }
                      alt=""
                    />
                  </th>
                  <th onClick={() => requestSort("speed")}>
                    <span className="mr-sm">Speed</span>
                    <img
                      src={
                        sortConfig.direction === "asc" &&
                          sortConfig.key === "speed"
                          ? UpArrowIcon
                          : DownArrowIcon
                      }
                      alt=""
                    />
                  </th>
                  <th onClick={() => requestSort("fuel")}>
                    <span className="mr-sm">Fuel Origin{" "}</span>
                    <img
                      src={
                        sortConfig.direction === "asc" &&
                          sortConfig.key === "fuel"
                          ? UpArrowIcon
                          : DownArrowIcon
                      }
                      alt=""
                    />
                  </th>
                  <th onClick={() => requestSort("eufm")}>
                    <span className="mr-sm">EUFM Credit{" "} </span>
                    <img
                      src={
                        sortConfig.direction === "asc" &&
                          sortConfig.key === "eufm"
                          ? UpArrowIcon
                          : DownArrowIcon
                      }
                      alt=""
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedData.map((item, index) => {
                  let smallestMinSpeed = speedDataset[0][0]?.minSpeed;
                  let largestMaxSpeed = speedDataset[0][0]?.maxSpeed;

                  speedDataset.forEach((i) => {
                    i.forEach((speed) => {
                      if (speed.minSpeed < smallestMinSpeed) {
                        smallestMinSpeed = speed.minSpeed;
                      }
                      if (speed.maxSpeed > largestMaxSpeed) {
                        largestMaxSpeed = speed.maxSpeed;
                      }
                    });
                  });
                  return (
                    <tr key={index + 5}>
                      <td>{item.vessel}</td>
                      <td className="d-flex justify-content-center">
                        <div
                          className={`cii-data`}
                          style={{ backgroundColor: item.ciiBackground }}
                        >
                          <p className="cii-designation"> {item.CII}</p>
                        </div>
                      </td>
                      <td>
                        {item.speed.map((speed, i) => {
                          const totalMinMax =
                            largestMaxSpeed - smallestMinSpeed;
                          let left = speed.minSpeed - smallestMinSpeed;
                          left = (left / totalMinMax) * 100;

                          let leftOptimalSpeed;
                          let leftTargetSpeed;

                          const roundedOptimalSpeed =
                            +speed.optimalSpeed.toFixed(1);

                          leftOptimalSpeed =
                            100 -
                            ((largestMaxSpeed - speed.optimalSpeed) /
                              totalMinMax) *
                            100;

                          leftOptimalSpeed = `calc(${Math.abs(
                            leftOptimalSpeed
                          )}% - .75em)`;

                          leftTargetSpeed =
                            100 -
                            ((largestMaxSpeed - speed.targetSpeed) /
                              totalMinMax) *
                            100;

                          return (
                            <div className="position-relative" key={index + 3}>
                              <div
                                className="speed-main-container"
                                style={{
                                  marginLeft: `${left}%`,
                                  width: `${((speed.maxSpeed - speed.minSpeed) /
                                    totalMinMax) *
                                    100
                                    }%`,
                                }}
                              >
                                <CustomTooltip
                                  tooltipText={`Min Speed: ${speed.minSpeed}`}
                                  placement="top"
                                >
                                  <div className="min-speed"></div>
                                </CustomTooltip>
                                <CustomTooltip
                                  tooltipText={`Optimal Speed: ${roundedOptimalSpeed} kt`}
                                  placement="top"
                                >
                                  <div
                                    className={`optimal-speed ${animate ? "slide-animation" : ""
                                      }`}
                                    style={{
                                      left: leftOptimalSpeed,
                                      backgroundColor: `${speed.celerityColor}`,
                                    }}
                                  ></div>
                                </CustomTooltip>
                                <CustomTooltip
                                  tooltipText={`Target Speed: ${speed.targetSpeed}`}
                                  placement="top"
                                >
                                  <div
                                    className="targated-speed"
                                    style={{
                                      left: `${leftTargetSpeed}%`,
                                    }}
                                  ></div>
                                </CustomTooltip>
                                <CustomTooltip
                                  tooltipText={`Max Speed: ${speed.maxSpeed}`}
                                  placement="top"
                                >
                                  <div
                                    className="max-speed"
                                    style={{
                                      left: `${100 -
                                        ((largestMaxSpeed - speed.maxSpeed) /
                                          totalMinMax) *
                                        100
                                        }%`,
                                    }}
                                  ></div>
                                </CustomTooltip>
                              </div>
                            </div>
                          );
                        })}
                      </td>
                      <td>
                        <div className="fuel-container">
                          {item?.fuel.map((p, i) => {
                            const totalData = fuelMixDataset[index].reduce(
                              (total, p) => total + p.data,
                              0
                            );

                            let percentage = (p.data / totalData) * 70;
                            const tooltip = (p.data * 100).toFixed(0);

                            return (
                              <React.Fragment key={p.data}>
                                <CustomTooltip
                                  tooltipText={`${p.label}: ${formatNumber(
                                    tooltip
                                  )}%`}
                                  placement="top"
                                >
                                  <div
                                    className="fuel-bg"
                                    style={{
                                      width: `${percentage}%`,
                                      backgroundColor: p.backgroundColor,
                                      marginRight: "1px",
                                    }}
                                  ></div>
                                </CustomTooltip>
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </td>
                      <td className="p-0">
                        {item?.eufm.map((e, i) => {
                          const divWidth = (e.miniKPIlength * 100).toFixed(0);
                          return (

                            <div className="row w-100" key={i + 1}>
                              <div className="col-6 p-0">
                                <div className="eufm-chart">
                                  {e.fmcredit_mtCO2eq < 0 && (
                                    <div className="d-flex justify-content-end">
                                      <CustomTooltip
                                        tooltipText={`Pool consumer: ${formatNumber(
                                          e.fmcredit_mtCO2eq
                                        )} [${eufmData.fmcreditsUnits}]`}
                                        placement="top"
                                      >
                                        <div
                                          className={`draw-box`}
                                          style={{ width: `${-divWidth}%` }}
                                        ></div>
                                      </CustomTooltip>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-6 p-0">
                                {e.fmcredit_mtCO2eq > 0 && (
                                  <div className="d-flex justify-content-start">
                                    <CustomTooltip
                                      tooltipText={`Pool contributor: ${formatNumber(
                                        e.fmcredit_mtCO2eq
                                      )} [${eufmData.fmcreditsUnits}]`}
                                      placement="top"
                                    >
                                      <div
                                        className={`draw-box-2`}
                                        // className={`draw-box-2 ${
                                        //   animate ? "slide-animation-new" : ""
                                        // }`}
                                        style={{
                                          width: `${divWidth}%`,
                                        }}
                                      ></div>
                                    </CustomTooltip>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <InfoModal
        ref={recommendationInfoRef}
        show={show}
        handleClose={handleClose}
        data={
          data?.mySpeedResults?.comments
            ? data?.mySpeedResults?.comments
            : "No data"
        }
      />
    </div>
  );
};

export default RecommendationTab;
