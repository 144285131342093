import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { formatNumber } from "../../utils/formatter";
import Loading from "../Loading";
import InfoModal from "../InfoModal";
import { useSelector } from "react-redux";
import { barPatterns } from "../../utils/barPatterns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const LeviesTab = ({ activeTab, showBtn = true }) => {
  const { fromYear } = useSelector((state) => state.selectedYearItem);
  const ets = useSelector((state) => state.pdfData);
  const legislativeScenarioName = ets.legislativeScenarioName;
  const pricingScenarioName = ets.priceScenarioName;

  // true => show IMO data and labels, false => show EU-ETS
  const [isIMO, setIsIMO] = useState(false);

  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [costUsd, setCostUsd] = useState([]);
  const [chartKey, setChartKey] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const LeviesContainerRef = useRef(null);
  const chartRef = useRef(null);

  useEffect(() => {
    // Rebuild the chart data whenever we get new ETS data or toggle
    if (Object.keys(ets).length > 0) {
      const dataObj = isIMO
        ? ets?.myIMOResults?.myResultsIMO
        : ets?.myETSResults?.myResultsETS;

      if (!dataObj) return;

      // Cost field differs depending on toggle
      const costKey = isIMO ? "imocostUSD" : "etscostUSD";

      const theseLabels = Object.keys(dataObj);

      const pollutantNames = Array.from(
        new Set(
          Object.values(dataObj).flatMap((vessel) =>
            Object.values(vessel).map((p) => p.pollutantName)
          )
        )
      );

      // Build the chart’s dataset array
      const computedDatasets = pollutantNames.map((pollutant) => {
        const costData = theseLabels.map((label) => {
          const vesselData = dataObj[label];
          if (vesselData?.[pollutant]) {
            return +vesselData[pollutant][costKey].toFixed(2);
          }
          return 0;
        });

        const co2eqData = theseLabels.map((label) => {
          const vesselData = dataObj[label];
          return vesselData?.[pollutant]
            ? vesselData[pollutant].co2eq_EmittedMt
            : 0;
        });

        const quantityEmittedData = theseLabels.map((label) => {
          const vesselData = dataObj[label];
          return vesselData?.[pollutant]
            ? vesselData[pollutant].quantityEmittedMt.toFixed(2)
            : 0;
        });

        // Accumulate total cost per vessel (used in tooltips)
        const vesselTotalCostArray = [];
        Object.values(dataObj).forEach((vessel) => {
          let totalCost = 0;
          Object.values(vessel).forEach((fuel) => {
            totalCost += fuel[costKey];
          });
          vesselTotalCostArray.push(totalCost);
        });
        setCostUsd(vesselTotalCostArray);

        // Collect colors
        const backgroundColors = theseLabels.map((label) => {
          const vesselData = dataObj[label];
          return vesselData && vesselData[pollutant]
            ? vesselData[pollutant].renderStyle.myColor
            : "";
        });
        const uniqueColors = [...new Set(backgroundColors.filter(Boolean))];

        return {
          label: pollutant,
          data: costData,
          dataetscostUSD: co2eqData, // keep this name for minimal changes
          quantityEmittedMt: quantityEmittedData,
          backgroundColor: uniqueColors,
          barThickness: 10,
          border: 0,
          barPercentage: 0.8,
          categoryPercentage: 0.5,
        };
      });

      setLabels(theseLabels);
      setDatasets(computedDatasets);
    }
  }, [ets, isIMO]);

  useEffect(() => {
    if (activeTab === 2) {
      setChartKey((prev) => prev + 1);
    }
  }, [activeTab]);

  // Create the chart data object
  const dataChart = {
    labels,
    datasets,
  };

  // For toggling chart labels, headings, etc.
  const costType = isIMO ? "IMO" : "EU-ETS";

  // Let’s rename the heading accordingly
  // If isIMO => "IMO levies"
  // else => "EU-ETS allowance costs"
  const titleText = isIMO ? "IMO levies" : "EU-ETS allowance costs";

  // Provide a custom tooltip positioner
  Tooltip.positioners.myCustomPositionerLeft = function (elements, eventPosition) {
    const tooltip = this;
    const chart = tooltip.chart;
    const x = eventPosition.x;
    const y = eventPosition.y;

    const chartArea = chart.chartArea;
    const leftEdge = chartArea.left;
    const rightEdge = chartArea.right;
    const topEdge = chartArea.top;
    const bottomEdge = chartArea.bottom;

    const margin = 20;

    // Hide tooltip if mouse is out of the chart area
    if (
      x < leftEdge - margin ||
      x > rightEdge + margin ||
      y < topEdge - margin ||
      y > bottomEdge + margin
    ) {
      tooltip.active = [];
      tooltip.update(true);
      return false;
    }

    let xAlign = "right";
    if (x <= leftEdge + margin) {
      xAlign = "left";
    } else if (x >= rightEdge - margin) {
      xAlign = "right";
    }

    return {
      x: x,
      y: y,
      xAlign: xAlign,
      yAlign: "center",
    };
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "y",
    scales: {
      x: {
        stacked: true,
        grid: { display: true },
        display: true,
        title: {
          display: true,
          text: `${costType} Cost [USD]`,
          font: {
            size: 15,
            weight: "bold",
            family: "Cabin, sans-serif",
          },
          padding: { top: 30 },
        },
        ticks: {
          callback: (value) => "$" + formatNumber(value),
          color: "#212529",
          font: {
            size: 15,
            family: "Cabin, sans-serif",
          },
        },
      },
      y: {
        stacked: true,
        grid: { display: true },
        ticks: {
          crossAlign: "far",
          color: "#000000",
          padding: 20,
          font: {
            size: 15,
            weight: "bold",
            family: "Cabin, sans-serif",
          },
          autoSkip: false,
        },
        border: { display: false },
      },
    },
    layout: {
      padding: { top: 75 },
    },
    plugins: {
      datalabels: { display: false },
      tooltip: {
        position: "myCustomPositionerLeft",
        displayColors: false,
        callbacks: {
          title: (tooltipItems) => {
            if (tooltipItems[0]) {
              const { label, dataset } = tooltipItems[0];
              return `${label} - ${dataset.label}`;
            }
            return "";
          },
          label: (context) => {
            if (!context) return "";
            const dataIndex = context.dataIndex;
            const qt = context.dataset.quantityEmittedMt[dataIndex];
            const usd = context.dataset.dataetscostUSD[dataIndex].toFixed(2);

            // Convert vessel total cost to array of strings
            const totalCostArr = costUsd.map((val) => val.toFixed(0));
            const totalCost = "$" + formatNumber(totalCostArr[dataIndex]);

            const costLine = `${costType} cost: ${context?.formattedValue} USD`;
            const totalLine = `Total ${costType}: ${totalCost}`;

            // Build a divider that’s at least as wide as the longest tooltip line
            const maxLineLength = Math.max(
              `Quantity Emitted: ${formatNumber(qt)} mt `.length,
              `CO2 eq: ${formatNumber(usd)} mt`.length,
              costLine.length,
              totalLine.length
            );
            const divider = "-".repeat(maxLineLength + 5);

            return [
              `Quantity Emitted: ${formatNumber(qt)} mt `,
              `CO2 eq: ${formatNumber(usd)} mt`,
              costLine,
              divider,
              totalLine,
            ];
          },
        },
      },
      legend: {
        position: "bottom",
        labels: {
          padding: 40,
          color: "#212529",
          font: {
            size: 15,
            family: "Cabin, sans-serif",
          },
        },
      },
    },
  };

  useEffect(() => {
    // Scroll to chart area if we open the modal
    if (showModal) {
      LeviesContainerRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [showModal]);

  const handleClose = () => {
    setShowModal(false);
    chartRef?.current?.scrollIntoView({ behavior: "auto" });
  };


    // Vertical sizing of chart
    const barHeight = 60; // for example, 40px per bar
    const extraPadding = 200; // top/bottom padding
    const chartHeight = dataChart.labels.length * barHeight + extraPadding;


  return (
    <div ref={LeviesContainerRef}>
     <div className="tab-content-title d-flex justify-content-between flex-wrap align-items-start">
        {/* Left side: Title + Subtitles stacked */}
        <div>
          <h2>
          {titleText} <span className="fs-4 ms-2">{fromYear.item}</span>
          </h2>
          {/* Subtitles, smaller font */}
          <div className="fs-6">
            <span className="d-block">
              <span className="fw-normal">Legislative Scenario:</span>{" "}
              <span className="fw-bold">{legislativeScenarioName}</span>
            </span>
            <span className="d-block">
              <span className="fw-normal">Pricing Scenario:</span>{" "}
              <span className="fw-bold">{pricingScenarioName}</span>
            </span>
          </div>
        </div>

        {/* Right side: Toggle + Button */}

        {showBtn && (
          <div className="d-flex flex-wrap align-items-center gap-3 mt-2 mt-lg-0">
            {/* Toggle */}
            <div className="form-check form-switch ft-1rem d-flex align-items-center gap-2 justify-content-start">
              <span className="text-nowrap">EU-ETS</span>
              <input
                className="form-check-input cursor-pointer no-dim-toggle"
                type="checkbox"
                id="custom-switch"
                checked={isIMO}
                onChange={() => setIsIMO((prev) => !prev)}
                style={{ marginLeft: "5px" }} // Adjust margin if necessary
              />
              <span className="text-nowrap">IMO-MTM</span>
            </div>

            {/* Notes and Sources button */}
            <button className="btn btn-primary" onClick={() => setShowModal(!showModal)}>
              Notes and Sources
            </button>
          </div>

        )}
      </div>

      <div className="fleet-chart-container h-auto">
        {Object.keys(ets).length === 0 ? (
          <div className="d-flex align-items-center justify-content-center h-100">
            <Loading loading={true} />
          </div>
        ) : (
          <div
          style={{
            width: "100%",
            height: `${chartHeight}px`, // dynamic vertical size
            position: "relative"
          }}
        >
          <Bar
            data={dataChart}
            options={options}
            plugins={[barPatterns]}
            key={chartKey}
          />
           </div>
        )}
      </div>

      <InfoModal
        ref={chartRef}
        show={showModal}
        handleClose={handleClose}
        data={
          isIMO ? ets?.myIMOResults?.comments : ets?.myETSResults?.comments
        }
      />
    </div>
  );
};

export default LeviesTab;
