import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "../pages/Home";
import Faq from "../pages/Faq";
import FleetWideResult from "../pages/FleetWideResult";

export default function AppNavigation() {
  // const permissions = useSelector((state) => state.permissions.permissions);

  // const canAccessRoute = (requiredPermission) => {
  //   const today = new Date();
  //   return permissions.some((permission) => {
  //     const permissionDate = new Date(permission.expirationDate);
  //     return (
  //       permission.licensePermission.permissionName === requiredPermission &&
  //       permissionDate >= today
  //     );
  //   });
  // };

  return (
    <Routes>
      <Route exact path="/" element={<Home />}></Route>
      <Route exact path="/faqs" element={<Faq />}></Route>
      <Route
        exact
        path="/fleetwideresult"
        element={<FleetWideResult />}
      ></Route>
    </Routes>
  );
}
