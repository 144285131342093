import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { formatNumber } from "../../utils/formatter";
import Loading from "../Loading";
import InfoModal from "../InfoModal";
import { useDispatch, useSelector } from "react-redux";
import { setChecked } from "../../redux/reducers/emissionSwitchChecked";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const EmissionTab = ({ activeTab, showBtn = true }) => {
  const dispatch = useDispatch();

  const [label, setLabel] = useState([]);
  const [dataset, setDataset] = useState([]);
  const [chartKey, setChartKey] = useState(0);
  const [show, setShow] = useState(false);

  const checked = useSelector((state) => state.emissionSwitchChecked);
  const { fromYear } = useSelector((state) => state.selectedYearItem);

  const handleChange = (event) => {
    dispatch(setChecked(event.target.checked));
  };

  const emission = useSelector((state) => state.pdfData);
  const legislativeScenarioName = emission.legislativeScenarioName;
  const pricingScenarioName = emission.priceScenarioName;

  useEffect(() => {
    if (Object.keys(emission).length > 0) {
      const myResultsEmissions =
        emission?.myResultsEmissions?.myResultsEmissions;
      const labels = Object.keys(myResultsEmissions);
      const pollutantNames = Array.from(
        new Set(
          Object.values(myResultsEmissions).flatMap((location) =>
            Object.values(location).flatMap(
              (pollutant) => pollutant.pollutantName
            )
          )
        )
      );

      const datasets = pollutantNames.map((pollutant) => {
        const co2eq_EmittedMt = labels.map((label) => {
          const etsData = myResultsEmissions[label];
          return etsData[pollutant]
            ? etsData[pollutant].co2eq_EmittedMt.toFixed(2)
            : 0;
        });

        const quantityEmittedMt = labels.map((label) => {
          const etsData = myResultsEmissions[label];
          return etsData[pollutant]
            ? etsData[pollutant].quantityEmittedMt.toFixed(2)
            : 0;
        });

        const backgroundColors = labels.map((label) => {
          const etsData = myResultsEmissions[label];
          return etsData[pollutant]
            ? etsData[pollutant].renderStyle.myColor
            : "";
        });
        const filteredColors = backgroundColors.filter((color) => color !== "");
        const backgroundColor = Array.from(new Set(filteredColors));

        if (checked) {
          return {
            label: pollutant,
            data: co2eq_EmittedMt,
            quantityEmittedMt: quantityEmittedMt,
            co2eq_EmittedMt: co2eq_EmittedMt,
            backgroundColor,
            barThickness: 10,
            border: 0,
            barPercentage: 0.6,
            categoryPercentage: 0.4,
          };
        } else {
          return {
            label: pollutant,
            data: quantityEmittedMt,
            quantityEmittedMt: quantityEmittedMt,
            co2eq_EmittedMt: co2eq_EmittedMt,
            backgroundColor,
            barThickness: 10,
            border: 0,
            barPercentage: 0.6,
            categoryPercentage: 0.4,
          };
        }
      });
      setDataset(datasets);
      setLabel(labels);
    }
  }, [emission, checked]);

  useEffect(() => {
    if (activeTab === 4) {
      setChartKey((prevKey) => prevKey + 1);
    }
  }, [activeTab]);

  const dataEts = {
    labels: label,
    datasets: dataset,
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "y",
    scales: {
      x: {
        stacked: true,
        grid: {
          display: true,
        },
        display: true,
        title: {
          display: true,
          text: `Emissions [mt]`,
          font: {
            size: 15,
            weight: "bold",
            family: "Cabin, sans-serif"
          },
          padding: {
            top: 30,
          },
        },
        ticks: {
          color: "#212529",
          font: {
            size: 15,
            family: "Cabin, sans-serif"
          },
        },
      },
      y: {
        stacked: true,
        grid: {
          display: true,
        },
        ticks: {
          crossAlign: "far",
          color: "#000000",
          padding: 20,
          font: {
            size: 15,
            weight: "bold",
            family: "Cabin, sans-serif"

          },
          autoSkip: false,
        },
        border: {
          display: false,
        },
      },
    },
    layout: {
      padding: {
        top: 75
      }
    },
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        position: "myCustomPositionerLeft",
        displayColors: false,
        callbacks: {
          label: function (context) {
            if (context !== undefined) {
              const dataIndex = context.dataIndex;
              const Mt = context.dataset.quantityEmittedMt[dataIndex];
              const co2 = context.dataset.co2eq_EmittedMt[dataIndex];
              return [
                `Quantity Emitted: ${formatNumber(Mt)} mt`,
                `CO2 eq: ${formatNumber(co2)} mt `,
              ];
            }
            return context.label;
          },
          title: function (context) {
            if (context[0] !== undefined) {
              return context[0].label + "-" + context[0].dataset.label;
            }
          },
        },
      },
      legend: {
        position: "bottom",
        labels: {
          padding: 40,
          color: "#212529",
          font: {
            size: 15,
            family: "Cabin, sans-serif"
          },
        },
      },
    },
  };

  const EmissionContainerRef = useRef(null);
  const emissionChartRef = useRef(null);

  useEffect(() => {
    if (show) {
      EmissionContainerRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [show]);

  const handleClose = () => {
    setShow(false);
    emissionChartRef?.current?.scrollIntoView({ behavior: "auto" });
  };

    // Vertical sizing of chart
    const barHeight = 60; // for example, 40px per bar
    const extraPadding = 200; // top/bottom padding
    const chartHeight = dataEts.labels.length * barHeight + extraPadding;

  return (
    <div ref={EmissionContainerRef}>
      <div className="tab-content-title d-flex justify-content-between flex-wrap align-items-start">
        {/* Left side: Title + Subtitles stacked */}
        <div>
          <h2>
            Emissions <span className="fs-4"> {fromYear.item}</span>
          </h2>
          {/* Subtitles, smaller font */}
          <div className="fs-6">
            <span className="d-block">
              <span className="fw-normal">Legislative Scenario:</span>{" "}
              <span className="fw-bold">{legislativeScenarioName}</span>
            </span>
            <span className="d-block">
              <span className="fw-normal">Pricing Scenario:</span>{" "}
              <span className="fw-bold">{pricingScenarioName}</span>
            </span>
          </div>
        </div>

        {showBtn && (
          <div className="d-flex flex-wrap align-items-center gap-3 mt-2 mt-lg-0">
            {/* Toggle */}
            <div className="form-check form-switch ft-1rem d-flex align-items-center gap-2 justify-content-start">
              <span className="text-nowrap">mt Pollutant</span>
              <input
                className="form-check-input cursor-pointer no-dim-toggle"
                type="checkbox"
                id="custom-switch"
                checked={checked}
                onChange={handleChange}
                style={{ marginLeft: "5px" }} // Adjust margin if necessary
              />
              <span className="text-nowrap">mt CO2-eq</span>
            </div>

            {/* Notes and Sources button */}
            <div>
              <button className="btn btn-primary" onClick={() => setShow(!show)}>
                Notes and Sources
              </button>
            </div>
          </div>
        )}

      </div>
      <div className="fleet-chart-container h-auto">
        {Object.keys(emission).length === 0 &&
          label.length === 0 &&
          dataset.length === 0 && (
            <div className="d-flex align-items-center justify-content-center h-100">
              <Loading loading={Object.keys(emission).length === 0} />
            </div>
          )}
        {Object.keys(emission).length > 0 &&
          label.length > 0 &&
          dataset.length > 0 && (
            <div
            style={{
              width: "100%",
              height: `${chartHeight}px`, // dynamic vertical size
              position: "relative"
            }}
          >
              <Bar 
              data={dataEts} 
              options={options} 
              key={chartKey} />
              </div>
          )}
      </div>
      <InfoModal
        ref={emissionChartRef}
        show={show}
        handleClose={handleClose}
        data={emission?.myResultsEmissions?.comments}
      />
    </div>
  );
};

export default EmissionTab;
