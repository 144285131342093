import React, { useState, useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  LineController,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import annotationPlugin from "chartjs-plugin-annotation";
import pattern from "patternomaly";

import { formatNumber } from "../../utils/formatter";
import Loading from "../Loading";
import InfoModal from "../InfoModal";
import { useSelector } from "react-redux";

// 1) Register ChartJS + annotation plugin
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  annotationPlugin
);

const IntensityTab = ({ activeTab, showBtn = true }) => {
  const { fromYear } = useSelector((state) => state.selectedYearItem);
  const euFm = useSelector((state) => state.pdfData);
  const legislativeScenarioName = euFm.legislativeScenarioName;
  const pricingScenarioName = euFm.priceScenarioName;

  // 2) This toggle decides if we’re displaying FEUM (unchecked) or IMO MTM (checked)
  const [checked, setChecked] = useState(false);
  const handleChange = (e) => {
    setChecked(e.target.checked);
  };

  // State for chart
  const [label, setLabel] = useState([]);
  const [dataset, setDataset] = useState([]);

  // Keep track of intensity & credits
  const [reportedFm, setReportedFm] = useState([]);
  const [unaidedFm, setUnaidedFm] = useState([]);
  const [intraPoolCredit_as_Co2_365, setIntraPoolCredit_as_Co2_365] = useState([]);
  const [externalCredit_as_Co2_365, setExternalPoolCredit_as_Co2_365] = useState([]);
  const [intraPoolCredit_as_intensityDelta, setIntraPoolCredit_as_intensityDelta] = useState([]);
  const [externalPoolCredit_as_intensityDelta, setExternalPoolCredit_as_intensityDelta] =
    useState([]);
  const [externalPayment, setExternalPayment] = useState([]);

  const [chartKey, setChartKey] = useState(0);
  const [show, setShow] = useState(false);
  const IntensityContainerRef = useRef(null);
  const euFmChartRef = useRef(null);

  useEffect(() => {
    // 3) Decide which data source to pull from
    //    FEUM => myFMResults.myResultsFM
    //    IMO  => myIMOMTMIntensityResults.myResultsIMO
    const resultsData = checked
      ? euFm?.myIMOMTMIntensityResults?.myResultsIMO
      : euFm?.myFMResults?.myResultsFM;

    if (!resultsData) return;

    const labels = Object.keys(resultsData);

    // For FEUM sorting logic (intra-pool), we may not even need it for IMO, but we can keep it consistent:
    const sortingArray = labels.map((name) => ({
      name,
      poolTrade_as_intensityDelta: resultsData[name]?.poolTrade_as_intensityDelta || 0
    }));

    // Sort ascending by poolTrade_as_intensityDelta, tie-break by reportedIntensity
    sortingArray.sort((a, b) => {
      if (a.poolTrade_as_intensityDelta !== b.poolTrade_as_intensityDelta) {
        return a.poolTrade_as_intensityDelta - b.poolTrade_as_intensityDelta;
      }
      return (
        (resultsData[a.name]?.reportedIntensity_gCo2eq_MJ || 0) -
        (resultsData[b.name]?.reportedIntensity_gCo2eq_MJ || 0)
      );
    });

    const sortedLabels = sortingArray.map((item) => item.name);

    // Build arrays
    const reportedFMintensity = sortedLabels.map(
      (name) => resultsData[name]?.reportedIntensity_gCo2eq_MJ || 0
    );
    const intensity_UnaidedByF = sortedLabels.map(
      (name) => resultsData[name]?.unaidedIntensity_gCo2eq_MJ || 0
    );
    const intraPool_Co2_365 = sortedLabels.map(
      (name) => (resultsData[name]?.poolTrade_gCO2eq_basis365 || 0) / 1e6
    );
    const external_Co2_365 = sortedLabels.map(
      (name) => (resultsData[name]?.externalCredits_gCO2eq_basis365 || 0) / 1e6
    );
    const intraPool_intensityDelta = sortedLabels.map(
      (name) => resultsData[name]?.poolTrade_as_intensityDelta || 0
    );
    const external_intensityDelta = sortedLabels.map(
      (name) => resultsData[name]?.externalCredit_as_intensityDelta || 0
    );
    const externalPayment = sortedLabels.map(
      (name) => resultsData[name]?.external_intensity_penalty_USD || 0
    );

    // Save to state
    setReportedFm(reportedFMintensity);
    setUnaidedFm(intensity_UnaidedByF);
    setIntraPoolCredit_as_Co2_365(intraPool_Co2_365);
    setExternalPoolCredit_as_Co2_365(external_Co2_365);
    setIntraPoolCredit_as_intensityDelta(intraPool_intensityDelta);
    setExternalPoolCredit_as_intensityDelta(external_intensityDelta);
    setExternalPayment(externalPayment);


    // 4) Build datasets for FEUM vs. IMO
    let datasetResult = [];
    if (!checked) {
      // FEUM mode
      datasetResult = [
        {
          label: "Base",
          data: sortedLabels.map((_, i) =>
            Math.min(reportedFMintensity[i], intensity_UnaidedByF[i])
          ),
          backgroundColor: "gray",
          barThickness: 20
        },
        {
          label: "IntraPool-Consumer",
          data: sortedLabels.map((_, i) => {
            const val = intraPool_intensityDelta[i];
            return val < 0 ? -val : 0;
          }),
          backgroundColor: pattern.draw("diagonal", "red"),
          barThickness: 20
        },
        {
          label: "IntraPool-Provider",
          data: sortedLabels.map((_, i) => {
            const val = intraPool_intensityDelta[i];
            return val > 0 ? val : 0;
          }),
          backgroundColor: "green",
          barThickness: 20
        },
        {
          label: "Remedial Units",
          data: sortedLabels.map((_, i) => {
            const val = external_intensityDelta[i];
            return val > 0 ? val : 0;
          }),
          backgroundColor: pattern.draw("diagonal", "#333333"),
          barThickness: 20
        },
        {
          label: "External Sale",
          data: sortedLabels.map((_, i) => {
            const val = external_intensityDelta[i];
            return val < 0 ? -val : 0;
          }),
          backgroundColor: "blue",
          barThickness: 20
        }
      ];
    } else {
      // IMO MTM mode (no pooling)
      datasetResult = [
        {
          label: "Base",
          data: sortedLabels.map((_, i) =>
            Math.min(reportedFMintensity[i], intensity_UnaidedByF[i])
          ),
          backgroundColor: "gray",
          barThickness: 20
        },
        {
          label: "Remedial Units",
          data: sortedLabels.map((_, i) => {
            const val = external_intensityDelta[i];
            return val > 0 ? val : 0;
          }),
          backgroundColor: pattern.draw("diagonal", "#333333"),
          barThickness: 20
        },
        {
          label: "External Sale",
          data: sortedLabels.map((_, i) => {
            const val = external_intensityDelta[i];
            return val < 0 ? -val : 0;
          }),
          backgroundColor: "blue",
          barThickness: 20
        }
      ];
    }

    setLabel(sortedLabels);
    setDataset(datasetResult);
  }, [euFm, checked]);

  // Prepare data for the chart
  const dataEUFM = {
    labels: label,
    datasets: dataset
  };

  // Force chart re-render if tab changes
  useEffect(() => {
    if (activeTab === 3) {
      setChartKey((prev) => prev + 1);
    }
  }, [activeTab]);

  // 5) Decide which max intensity to show
  const feumMax = euFm?.myFMResults?.maxGHG_intensitygCO2_MJ ?? 0;
  const imoMax = euFm?.myIMOMTMIntensityResults?.maxGHG_intensitygCO2_MJ ?? 0;
  const currentMaxLine = checked ? imoMax : feumMax;

  // 6) Build tooltip logic
  const tooltipCallbacks = {
    label: (context) => {
      // Only show the tooltip for the “Base” dataset
      if (context.datasetIndex !== 0) return "";

      const i = context.dataIndex;
      if (!reportedFm[i]) return ["No emissions data."];

      const at = reportedFm[i].toFixed(2);
      const un = unaidedFm[i].toFixed(2);
      let ext = Number(externalCredit_as_Co2_365[i]) || 0;
      if (Math.abs(ext) < 1e-6) ext = 0;
      let extFormatted = externalCredit_as_Co2_365[i].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      let payFormatted = externalPayment[i].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });



      let extraPoolMessage;
      let PaymentMessage = null;
      if (ext === 0) {
        extraPoolMessage = "No external purchase or sale.";
      } else if (ext > 0) {
        extraPoolMessage = `Remedial Units: ${extFormatted} [mtCO2] (under-compliant)`;
        PaymentMessage = `External Payment: ${payFormatted} [USD]`;
      } else {
        extraPoolMessage = `External sale: ${extFormatted} [mtCO2] (over-compliant)`;
      }

      if (!checked) {
        // FEUM tooltip with pooling info
        let pc = intraPoolCredit_as_Co2_365[i].toFixed(2);

        if (Math.abs(pc) < 1e-6) pc = 0;

        let intraPoolMessage;
        if (pc === 0) {
          intraPoolMessage = "No intra-pool intensity transfers.";
        } else if (pc > 0) {
          intraPoolMessage = `Intra-pool credit: ${pc} [mtCO2] (over-compliant)`;
        } else {
          intraPoolMessage = `Intra-pool credit: ${pc} [mtCO2] (under-compliant)`;
        }

        return [
          `Reported Intensity: ${at} [gCO2eq/MJ]`,
          `Unaided Intensity:  ${un} [gCO2eq/MJ]`,
          intraPoolMessage,
          extraPoolMessage,
          PaymentMessage
        ].filter(Boolean);
      } else {
        // IMO tooltip (no pooling)
        return [
          `Reported Intensity: ${at} [gCO2eq/MJ]`,
          `Unaided Intensity:  ${un} [gCO2eq/MJ]`,
          extraPoolMessage,
          PaymentMessage
        ].filter(Boolean);
      }
    },
    title: (context) => context[0].label
  };

  // 7) Chart options
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        stacked: true,
        grid: { display: true },
        min: 0,
        max: 100,
        ticks: {
          callback: (value) => formatNumber(value),
          font: { size: 15, family: "Cabin, sans-serif" }
        }
      },
      x: {
        stacked: true,
        grid: { display: true },
        ticks: {
          display: false,
          minRotation: 90,
          color: "#000000",
          padding: 1,
          font: { size: 14, weight: "bold", family: "Cabin, sans-serif" }
        }
      }
    },
    layout: { padding: { top: 75 } },
    plugins: {
      datalabels: {
        align: "end",
        anchor: "start",
        color: "#000000",
        borderRadius: 30,
        padding: { left: 10, right: 10, top: 8, bottom: 8 },
        rotation: -90,
        font: () => ({
          size: 15,
          weight: "bold",
          family: "Cabin, sans-serif"
        }),
        display: (context) => context.dataset.label === "Base",
        formatter: (value, context) => label[context.dataIndex]
      },
      legend: {
        position: "bottom",
        labels: {
          padding: 40,
          color: "#212529",
          font: {
            size: 15,
            family: "Cabin, sans-serif"
          },
          generateLabels: (chart) => {
            // Start with the default labels:
            let labels = ChartJS.defaults.plugins.legend.labels.generateLabels(chart);

            // 1) Always remove the first item in the legend:
            labels = labels.slice(1);

            // 2) If in IMO mode (checked == true), also remove pooling & external references:
            if (checked) {
              labels = labels.filter(
                (item) =>
                  !item.text.includes("IntraPool")
              );
            }

            return labels;
          }
        }
      },
      tooltip: {
        position: "nearest",
        displayColors: false,
        mode: "index",
        intersect: false,
        callbacks: tooltipCallbacks
      },
      annotation: {
        annotations: {
          maxGHGLine: {
            type: "line",
            yMin: currentMaxLine,
            yMax: currentMaxLine,
            borderColor: "red",
            borderDash: [2, 2],
            borderWidth: 2,
          }
        }
      }
    }
  };

  // Scroll logic for modal
  useEffect(() => {
    if (show) {
      IntensityContainerRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [show]);

  const handleClose = () => {
    setShow(false);
    euFmChartRef?.current?.scrollIntoView({ behavior: "auto" });
  };

  return (
    <div ref={IntensityContainerRef}>
      <div className="tab-content-title d-flex justify-content-between flex-wrap align-items-start">
        {/* Left side: Title + Subtitles stacked */}
        <div>
          <h2>
            {checked ? "IMO-MTM Intensity" : "FEUM Intensity"}{" "}
            <span className="fs-4">{fromYear?.item}</span>
          </h2>
          {/* Subtitles, smaller font */}
          <div className="fs-6">
            <span className="d-block">
              <span className="fw-normal">Legislative Scenario:</span>{" "}
              <span className="fw-bold">{legislativeScenarioName}</span>
            </span>
            <span className="d-block">
              <span className="fw-normal">Pricing Scenario:</span>{" "}
              <span className="fw-bold">{pricingScenarioName}</span>
            </span>
          </div>
        </div>

        {/* Right side: Toggle + Button */}

        {showBtn && (
          <div className="d-flex flex-wrap align-items-center gap-3 mt-2 mt-lg-0">
            {/* Toggle */}
            <div className="form-check form-switch ft-1rem d-flex align-items-center gap-2 justify-content-start">
              <span className="text-nowrap">FEUM</span>
              <input
                className="form-check-input cursor-pointer no-dim-toggle"
                type="checkbox"
                id="custom-switch"
                checked={checked}
                onChange={handleChange}
                style={{ marginLeft: "5px" }} // Adjust margin if necessary
              />
              <span className="text-nowrap">IMO-MTM</span>
            </div>

            {/* Notes and Sources button */}
            <div>
              <button className="btn btn-primary" onClick={() => setShow(!show)}>
                Notes and Sources
              </button>
            </div>
          </div>
        )}
      </div>


      <div className="fleet-chart-container ">
        {/* If no data is loaded, show Loading */}
        {(!euFm || Object.keys(euFm).length === 0) &&
          label.length === 0 &&
          dataset.length === 0 && (
            <div className="d-flex align-items-center justify-content-center h-100">
              <Loading loading />
            </div>
          )}

        {/* If data is present, show the chart */}
        {euFm &&
          Object.keys(euFm).length > 0 &&
          label.length > 0 &&
          dataset.length > 0 && (
            <Bar data={dataEUFM} options={options} key={chartKey} />
          )}
      </div>

      <InfoModal
        ref={euFmChartRef}
        show={show}
        handleClose={handleClose}
        data={
          checked
            ? euFm?.myIMOMTMIntensityResults?.comments
            : euFm?.myFMResults?.comments
        }
      />
    </div>
  );
};

export default IntensityTab;
