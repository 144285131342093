import React, { forwardRef} from "react";
import CiiChart from "./charts/CiiChart";
import LeviesTab from "./charts/LeviesTab";
import IntensityTab from "./charts/IntensityTab";
import EmissionTab from "./charts/EmissionTab";
import FuelTab from "./charts/FuelTab";
import RecommendationTab from "./charts/RecommendationTab";

const Pdf = forwardRef(
  (
    {
      RecommendationContainerRef,
      ciiContainerRef,
      LeviesContainerRef,
      IntensityContainerRef,
      EmissionContainerRef,
      FuelContainerRef,
      showPdfData,
    },
    ref
  ) => {
    return (
      <div className="pdf-container">
        <div>

          <div className="recommendation-table-pdf mt-5" ref={RecommendationContainerRef}>
            <RecommendationTab showBtn={false} />
          </div>

          <div className="cii-chart-pdf" ref={ciiContainerRef}>
            <CiiChart showBtn={false} />
          </div>

          <div className="ets-chart-pdf mt-5" ref={LeviesContainerRef}>
            <LeviesTab showBtn={false} />
          </div>

          <div className="intensity-chart-pdf mt-5" ref={IntensityContainerRef}>
            <IntensityTab showBtn={false} />
          </div>

          <div className="emission-chart-pdf mt-5" ref={EmissionContainerRef}>
            <EmissionTab showBtn={false} checked={false} />
          </div>

          {/* FuelTab */}
          <div className="fuel-chart-pdf mt-5" ref={FuelContainerRef}>
            <FuelTab showBtn={false} />
          </div>


        </div>
      </div>
    );
  }
);

export default Pdf;
