import React, {  useEffect, useRef, useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useDispatch, useSelector } from "react-redux";
import hamburgerIcon from "../assets/hamburger.svg";
import {
  Menu,
} from '@szhsin/react-menu';
import "../css/main.css";
import "../css/responsive.css";
import { allVesrionInfo, getOwner } from "../api/data";
import { formatDate } from "../utils/formatter";
import Loading from "./Loading";
import Avatar from 'react-avatar';
import ZJoule from "../assets/zjoule.png";
import { setPermissions } from "../redux/reducers/permissionsSlice";
import RouteEditorIcon from "../assets/routeEditorNavbar.svg";
import VesselEditorIcon from "../assets/vesselEditorNavbar.svg";
import PriceForecastIcon from "../assets/priceForecastNavbar.svg";
import RegulatoryContextIcon from "../assets/legislativeScenarioNavbar.svg";
import SettingsIcon from "../assets/settingsNavbar.svg";
import LogoutIcon from "../assets/logoutNavbar.svg";
import VesselTypeEditorIcon from "../assets/vesselTypeEditor.svg"
import useLogout from "../hooks/ useLogout";
import CustomTooltip from "./CustomTooltip";
import { reactAppConfig } from "../config/react-app-config";

const NavbarComponent = ({
  show = false,
  showfaq = false,
  showversion = false,
}) => {
  const dispatch = useDispatch();

  const infoModalref = useRef();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [versionData, setVersionData] = useState(null);
  const [scenarioLink, setScenarioLink] = useState(null);
  const [companyName, setCompanyName] = useState('');


  
  const [ownerInfo, setOwnerInfo] = useState(null);
  const [isOpen, setOpen] = useState(false)

  const isTruncate = true;
  const handleLogOut = useLogout();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const permissions = useSelector((state) => state.permissions.permissions);
  const canAccessRoute = (requiredPermission) => {
    const today = new Date();
    return permissions.some((permission) => {
      const permissionDate = new Date(permission.expirationDate);
      return (
        permission.licensePermission.permissionName === requiredPermission &&
        permissionDate >= today
      );
    });
  };

  const truncateEmail = (email) => {
    if (!email) return '';
    return email.length > 30 ? `${email.slice(0, 30)}...` : email;
  };

  const fullEmail = ownerInfo?.email || '';


  const getVersionInfo = () => {
    allVesrionInfo()
      .then((res) => {
        setVersionData(res.data);
      })
      .catch((err) => console.log("version data err", err));
  };


  useEffect(() => {
    if (localStorage.getItem("accessToken") != null) { 
      getOwnerPermission();
    }
  }, []);

  const getOwnerPermission = () => {
    getOwner()
      .then((result) => {
        setOwnerInfo(result.data);
        dispatch(setPermissions(result.data.owner.permissions));
        setCompanyName(result.data.owner.name); // Store the company name
      })
      .catch((err) => console.error("err get company name", err));
  };

  useEffect(() => {
    if (showversion === true) getVersionInfo();
  }, [showversion]);

 

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showModal &&
        infoModalref.current &&
        !infoModalref.current.contains(event.target)
      ) {
        setShowModal(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showModal, infoModalref]);

  const handleNavigation = (scenario) => {
    const url = reactAppConfig.scenarioManagerUrl+scenario;
    const identifier = localStorage.getItem("identifier")
    const scenarioUri = `${url}/${identifier}`
    setScenarioLink(scenarioUri);
    window.location.href = scenarioUri;
  }

  const handleOnClick = (editor) => {
    if(editor === "EditVesselTypes"){
      if (canAccessRoute("EditVesselTypes")) {
        handleNavigation("vesseltypeeditor");
      }
    }else if(editor === "EditVessels"){
      if (canAccessRoute("EditVessels")) {
        handleNavigation("vesseleditor");
      }
    }else if(editor === "EditLegislativeScenarios"){
      if (canAccessRoute("EditLegislativeScenarios")) {
        handleNavigation("legislativescenario");
      }
    }
  };
  

  return (
    <Navbar collapseOnSelect expand="lg bottom-border-1px p-0">
      <div className="nav-new-container">
        <Link className="navbar-brand d-flex align-items-center" to="/">
          <span className="span-lg">POOL.FM</span>
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className={`${showfaq || show ? "mx-auto" : "ms-auto"}`}>
            <div className="z-joule-container">
                <p>
                  Powered by{" "}
                </p>
                <span>
                  <NavLink to="https://z-joule.com/" target="_blank">
                    <img src={ZJoule} alt="" />
                  </NavLink>
                </span>
            </div>
          </Nav>
          <Nav className="align-items-center">
          {showfaq && (
  <>
    {/* 
      <NavLink to="/faqs" target="_blank">
        <button className="btn btn-secondary faq-button" type="button">
          FAQs
        </button>
      </NavLink> 
    */}
  </>
)}
            {show &&
              <Menu curose menuButton={<a className="cursor-pointer"><img src={hamburgerIcon} alt="close" /></a>}>
            
                  <div
                    id="basic-menu"
                    className="hamburger-dropdown">
                    <div className="user-info" style={{ position: 'relative', width:'16rem', height: '120px' }}>
                      <div style={{
                          background: 'var(--z-joule-com-linear-gossamer-curious-blue, linear-gradient(90deg, #06966B 0%, #2C8EC6 100%))',
                          width: '100%',
                          height: '120px',
                          position: 'relative',
                          borderRadius: ".5rem .5rem 0 0"
                        }}
                        >
                      </div>
                      <div style={{
                            position: 'absolute',
                            top: '60%',
                          }}>
                        <Avatar name={ownerInfo?.firstname} src="/static/images/avatar/2.jpg" >
                        </Avatar>
                      </div>
                    
                    </div>
                    <div className="d-flex align-items-center flex-column w-100">
                      <div className="mt-4">
                        <span className="custom-user-info d-flex justify-content-center">{ ownerInfo?.firstname}</span>
                        <span className="custom-user-info d-flex justify-content-center" style={{ fontSize: '0.8em' }}>{ companyName }</span>
                      </div>
                      <div className="pb-3 mt-3" style={{ borderBottom: '1px solid #DFDFDF' }}>
                    <div className="d-flex justify-content-center">

                      <CustomTooltip
                        tooltipText={ fullEmail.length > 25 ? fullEmail : '' } 
                        placement="top">
                          <span className="custom-user-info">{truncateEmail(fullEmail)}</span>
                      </CustomTooltip>
                    </div>
                  </div>
                </div>
                <ul className="d-grid settings-menu-window">
                    <li disabled={!canAccessRoute("EditRoutes")} onClick={() => alert("Edit Routes")}>
                      <img src={RouteEditorIcon} alt="" className="premium-icon" />
                      <span className="editor-menu-list-items" >Route Editor</span> 
                    </li>
                    <li 
                      onClick={() =>handleOnClick("EditVessels")}
                      className={`editor-menu-list-item ${!canAccessRoute("EditVessels")} ? "disabled" : none`} 
                      style={{ 
                        cursor: canAccessRoute("EditVessels") ? 'pointer' : 'not-allowed',
                        opacity: canAccessRoute("EditVessels") ? 1 : 0.5 
                      }}
                      disabled={!canAccessRoute("EditVessels")} 
                    >
                      <img src={VesselEditorIcon} alt="" className="premium-icon" />
                      <span className="editor-menu-list-items">Vessel Editor</span>
                    </li>
                    <li 
                      onClick={() => handleOnClick("EditVesselTypes")}
                      className={`editor-menu-list-item ${!canAccessRoute("EditVesselTypes") ? 'disabled' : ''}`} 
                      style={{ 
                        cursor: canAccessRoute("EditVesselTypes") ? 'pointer' : 'not-allowed',
                        opacity: canAccessRoute("EditVesselTypes") ? 1 : 0.5 
                      }}
                      disabled={!canAccessRoute("EditVesselTypes")} 
                    >
                      <img src={VesselTypeEditorIcon} alt="" className="premium-icon" />
                      <span className="editor-menu-list-items">Vessel Type Editor</span>
                    </li>
                    <a href={scenarioLink} >
                      <li onClick={() => handleNavigation("fuelpriceforecast")}>
                          <img src={PriceForecastIcon} alt="" className="premium-icon" />
                          <span className="editor-menu-list-items">Fuel Price Scenarios</span>
                      </li>
                    </a>
                  {/* <li disabled={!canAccessRoute("EditLegislativeScenarios")} onClick={() => navigate("/legislativescenario")}>
                      <img src={RegulatoryContextIcon} alt="" className="premium-icon" />
                      <span className="editor-menu-list-items"> Legislative Scenarios </span>
                  </li> */}
                  <li 
                      onClick={() => handleOnClick("EditLegislativeScenarios")}
                      className={`editor-menu-list-item ${!canAccessRoute("EditLegislativeScenarios") ? 'disabled' : ''}`} 
                      style={{ 
                        cursor: canAccessRoute("EditLegislativeScenarios") ? 'pointer' : 'not-allowed',
                        opacity: canAccessRoute("EditLegislativeScenarios") ? 1 : 0.5 
                      }}
                      disabled={!canAccessRoute("EditLegislativeScenarios")} 
                    >
                      <img src={RegulatoryContextIcon} alt="" className="premium-icon" />
                      <span className="editor-menu-list-items">Legislative Scenarios</span>
                    </li>
                </ul>
                <ul className="d-grid settings-menu-window" style={{ borderTop: '1px solid #DFDFDF' }}>
                    <li disabled={true }  onClick={() => setShowModal((prevShowModal) => !prevShowModal)} ref={infoModalref}>
                      <img src={SettingsIcon} alt="" className="premium-icon" />
                      <span className="editor-menu-list-items">Settings</span>
                  </li>
                  <li onClick={handleLogOut}>
                      <img src={LogoutIcon} alt="" className="premium-icon" />
                      <span className="editor-menu-list-items">Log Out</span>
                  </li>
                </ul>
              </div>
            </Menu>}
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default NavbarComponent;

export const InfoModal = ({ showModal, versionData }) => {
  const inputString = versionData?.build?.build?.number;
  const firstEightCharacters = inputString?.substring(0, 8);
  return (
    <>
      {showModal && (
        <div className="info-container">
          {!versionData ? (
            <div className="d-flex justify-content-center mt-5">
              <Loading loading={!versionData} height={35} width={35} />
            </div>
          ) : (
            <>
              <h3>
                PoolFM Application all designs, data, and algorithms subject to
                Copyright.
              </h3>
              <ul className="vessel-detail-container bg-transparent">
                <li>
                  <span className="key">Build number BE:</span>
                  <span className="value">
                    {" "}
                    {firstEightCharacters} (
                    {formatDate(versionData?.build?.time)})
                  </span>
                </li>
                <li>
                  <span className="key">Build number FE:</span>
                  <span className="value">
                    {" "}
                    {firstEightCharacters} (
                    {formatDate(versionData?.build?.time)})
                  </span>
                </li>
              </ul>
            </>
          )}
        </div>
      )}
    </>
  );
};
